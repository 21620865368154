import axios from "axios";

export const Post = (url, data, config) => axios.post(url, data, config)
export const Get = (url, config) => axios.get(url, config)
export const Put = (url, data, config) => axios.put(url, data, config)
export const Delete = (url, config) => axios.delete(url, config)

export const httpClient = {
  Post,
  Get,
  Put,
  Delete,
}