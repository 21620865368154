import Endpoints from "../constants/endpoints";
import { httpClient } from "../shared/httpClient";

const registerAccount = (model) => {
  return httpClient.Post(Endpoints.registerAccount, model)
}

const registerFacebook = (model) => {
  return httpClient.Post(Endpoints.registerFacebook, model)
}

const registerGoogle =(model) => {
  return httpClient.Post(Endpoints.registerGoogle, model)
}

const identityService = {
  registerAccount,
  registerFacebook,
  registerGoogle,
}

export default identityService;