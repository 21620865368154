import urlHelpers from "../helpers/urlHelpers";

const BASE_API = process.env.REACT_APP_BASE_API ?? ''

const Endpoints = {
  registerAccount: urlHelpers.combine(BASE_API, 'registerAccount/'),
  registerGoogle: urlHelpers.combine(BASE_API, "signup/google"),
  registerFacebook: urlHelpers.combine(BASE_API, "signup/facebook"),
  signinGoogle: urlHelpers.combine(BASE_API, "signin/google"),
  signinFacebook: urlHelpers.combine(BASE_API, "signin/facebook"),
}

export default Endpoints;