const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID ?? ''
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''

const Configs = {
  Facebook: {
    AppId: FACEBOOK_APP_ID,
  },
  Google: {
    ClientId: GOOGLE_CLIENT_ID
  }
}

export default Configs