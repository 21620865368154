import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import CuteGift from '../assets/images/cute.gif';
import haveaniceday from '../assets/images/haveaniceday.png';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaUser, FaPhone, FaKey, FaEnvelope, FaFacebookSquare, FaGooglePlusG } from 'react-icons/fa'
import identityService from '../services/identity.service';
import {useForm } from 'react-hook-form'
import toastifyClient from '../shared/toastifyClient';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useGoogleLogin } from '@react-oauth/google';
import { useLogin } from 'react-facebook';
import ProgressBar from 'react-bootstrap/ProgressBar';

function RegisterMB() {
  const { handleSubmit, register, formState } = useForm({})
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const loginGoogle = useGoogleLogin({
    onSuccess: (googleResponse) => {
      identityService.registerGoogle({token: `${googleResponse.access_token}`})
      .then(res => {
        if(res.data.isSuccess){
          toastifyClient.success("Tạo tài khoản thành công")
          window.location.href='https://ant-edu.ai/auth/login'

        } else {
          toastifyClient.error(res.data.errors?.[0]?.message ?? "Có lỗi trong quá trình tạo tài khoản")
        }
      })
      .catch(err => {
        console.log(err)
        toastifyClient.error("Server internal error")
      })
    }
  })

  const {login: loginFacebook, error, isLoading: isLoadingFacebook, status} = useLogin()

  const { errors } = formState

  const onRegisterClick = (data) => {
    setIsLoading(true)
    identityService.registerAccount(data)
      .then(res => {
        if (res.data.isSuccess) {
          setIsLoading(false)
          toastifyClient.success('Đăng ký thành công');
          window.location.href='https://ant-edu.ai/auth/login'
        }
        else {
          setIsLoading(false)
          toastifyClient.error(res.data?.errors?.[0]?.message || 'Có lỗi khi đăng ký')
        }
      })
      .catch(err => {
        setIsLoading(false)
        toastifyClient.error(err?.message || "Có lỗi trong quá trình đăng ký")
      })
  }
  const socialClick = () => {
    toastifyClient.info("Coming soon")
  }
  const onFacebookClick = async () => {
    var res = await loginFacebook({
      scope: "email public_profile",
      reauthorize: false,
    })
    
    identityService.registerFacebook({token: `${res.authResponse.accessToken}`})
    .then(res => {
      if(res.data.isSuccess){
        toastifyClient.success("Tạo tài khoản thành công")
        window.location.href='https://ant-edu.ai/auth/login'
      } else {
        toastifyClient.error(res.data.errors?.[0]?.message ?? "Có lỗi trong quá trình tạo tài khoản")
      }
    })
    .catch(err => {
      console.log(err)
      toastifyClient.error("Server internal error")
    })
  }

  const onGoogleClick = () => {
    loginGoogle()
  }

  return (
    <>
      <Container fluid className='register-mb'>
        <Col className='res-header text-center'>
          Đăng ký
        </Col>
        <Col className='res-img'>
          <Image className='hava-a-nice-day' src={haveaniceday} alt='hava a nice day' />
          <Image className='cute-gift' src={CuteGift} alt='Thank you' />
        </Col>
        <Col className='res-form'>
        <Stack direction="vertical" gap={2} className='login-form-container mx-auto'>
          <InputGroup className="mb-3">
            <InputGroup.Text><FaUser /></InputGroup.Text>
            <Form.Control {...register('displayName', { required: true })} placeholder="Your name" aria-label="Your name" isInvalid={!!errors?.displayName} />
            <Form.Control.Feedback type="invalid">Please provide a valid display name.</Form.Control.Feedback>
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroup.Text><FaEnvelope /></InputGroup.Text>
            <Form.Control
              {...register('email', { required: true })}
              type='email'
              placeholder="Email"
              aria-label="Email"
              autoComplete='off'
              isInvalid={!!errors?.email} />
            <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroup.Text><FaPhone /></InputGroup.Text>
            <Form.Control
              {...register('phoneNumber', { required: true })}
              placeholder="Phone number"
              aria-label="Phone number"
              autoComplete='off'
              isInvalid={!!errors?.phoneNumber} />
            <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroup.Text><FaKey /></InputGroup.Text>
            <Form.Control
              {...register('password', { required: true })}
              type='password'
              placeholder="Password"
              autoComplete='off'
              aria-label="Password"
              isInvalid={!!errors?.password} />
            <Form.Control.Feedback type="invalid">Password not match.</Form.Control.Feedback>
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroup.Text><FaKey /></InputGroup.Text>
            <Form.Control
              {...register('rePassword', {
                validate: {
                  Required: (val, formVals) => { return !val ? "Repassword is required" : true },
                  PasswordMatch: (val, formVals) => { return val !== formVals.password ? 'Password not match' : true },
                }
              })}
              type='password'
              placeholder="Repeat password"
              aria-label="Repeat password"
              autoComplete='off'
              isInvalid={!!errors?.rePassword} />
            <Form.Control.Feedback type="invalid">{errors.rePassword?.message}</Form.Control.Feedback>
          </InputGroup>
          <Button disabled={isLoading} className='bg-cyan' as="a" variant="primary" onClick={handleSubmit(onRegisterClick)}>
            {isLoading && <Spinner size='sm'/>} Create account
          </Button>
        </Stack>
        <Row className='mt-2'>
          <p className='text-center'>Already have an account? <a href="https://ant-edu.ai/auth/login" class="color-anttest">Log in</a></p>
        </Row>
        <Row className='mt-4 process-bar m-0 p-0 d-flex align-items-center'>
          <ProgressBar className='process-bar-item p-0'  now={100} />
          <div className='process-bar-text'> Register with</div>
          <ProgressBar className='process-bar-item p-0' now={100} />
        </Row>
        <Row className='mt-4 pb-4 social mx-auto gap-2'>
          <Col className='p-0'>
            
              <Button variant="primary" className='w-100 facebook d-flex justify-content-evenly' onClick={onFacebookClick}>
                <FaFacebookSquare size={30} />
                Facebook
              </Button>
            
          </Col>
          <Col className='p-0'>  
            <Button variant="danger" className='w-100 google d-flex justify-content-evenly' onClick={onGoogleClick}>
              <FaGooglePlusG size={30} />
              Google
            </Button>
          </Col>
        </Row>
        </Col>
      </Container>
    </>
  );
}

export default RegisterMB;
