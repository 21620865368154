import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Register from './pages/Register';
import Container from 'react-bootstrap/Container';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Error404 from './pages/Error404';
import Thankyou from './pages/Thankyou';

function App() {
  return (
  <Container fluid className='app'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Register />} />
          <Route path='/thank-you' element={<Thankyou />} />
          <Route path='*' element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </Container>
  );
}

export default App;
