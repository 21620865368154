const combine = (...args) => {

  return args.map(p => p.replace(/[/]+$/g, "")).join('/')
}
const isAbsolute = (url = '') => {
  if(url.startsWith('http://') || url.startsWith('https://')){
    return true;
  }
  return false;
}

const isRelative = (url = '') => {
  return !isAbsolute(url);
}
const urlHelpers = {
  combine,
  isAbsolute,
  isRelative,
}

export default urlHelpers;