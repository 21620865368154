import Image from "react-bootstrap/Image"
import img404 from '../assets/images/404.svg'
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import imgRocket from '../assets/images/rocket.svg'
import imgMoon from  '../assets/images/moon.svg'
import imgEarth from  '../assets/images/earth.svg'
import imgAstronaut from  '../assets/images/astronaut.svg'
import { useNavigate } from 'react-router-dom'

const Error404 = () => {
  const navigate = useNavigate();
  const onGoBackHome = () => {
    navigate('/', {replace: true})
  }
  return (

    <Container fluid className="stars">
      <Container className="central-body">
        <Image src={img404} alt={"404 Not Found"} className="image-404" />
        <Button className="btn-go-home" variant="outline" onClick={onGoBackHome}> Go back home</Button>
      </Container>

      <Container className="objects">
        <Image src={imgRocket} alt="rocket" className="object_rocket" />
        <Container className="earth_moon">
          <Image src={imgEarth} alt="rocket" className="object_earth" />
          <Image src={imgMoon} alt="rocket" className="object_moon" />
        </Container>
        <Container className="box_astronaut">
          <Image src={imgAstronaut} alt="rocket" className="object_astronaut" />
        </Container>
      </Container>
      <Container className="glowing_stars">
        <Container className="star"></Container>
        <Container className="star"></Container>
        <Container className="star"></Container>
        <Container className="star"></Container>
        <Container className="star"></Container>
      </Container>
    </Container>


  )
}

export default Error404