
import { isDesktop} from "react-device-detect";
import RegisterDesktop from './RegisterDesktop';
import RegisterMB from './RegisterMB';

function Register() {


  return (
    <>
      {isDesktop ? <RegisterDesktop /> : <RegisterMB/>}
    </>
  );
}

export default Register;
