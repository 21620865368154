import Image from "react-bootstrap/Image"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import imgRocket from '../assets/images/rocket.svg'
import imgMoon from '../assets/images/moon.svg'
import imgEarth from '../assets/images/earth.svg'
import imgAstronaut from '../assets/images/astronaut.svg'
import { TypeAnimation } from "react-type-animation"

const Thankyou = () => {
  // const navigate = useNavigate();
  const goToLearn = () => {
    // navigate('https://ant-edu.ai/', { replace: true, relative: false })
    window.location.href = "https://ant-edu.ai"
  }
  return (

    <Container fluid className="stars">
      <Container className="central-body">
        <TypeAnimation
          sequence={[
            '',
            500,
            'Thank',
            500,
            'Thank you',
            500,
            'Thank you for',
            500,
            'Thank you for register!',
            500,
          ]}
          speed={50}
          className='welcome-text'
          repeat={Infinity}
        />
        <Button className="btn-go-home" variant="outline" onClick={goToLearn}> Go to learn!</Button>
      </Container>

      <Container className="objects">
        <Image src={imgRocket} alt="rocket" className="object_rocket" />
        <Container className="earth_moon">
          <Image src={imgEarth} alt="rocket" className="object_earth" />
          <Image src={imgMoon} alt="rocket" className="object_moon" />
        </Container>
        <Container className="box_astronaut">
          <Image src={imgAstronaut} alt="rocket" className="object_astronaut" />
        </Container>
      </Container>
      <Container className="glowing_stars">
        <Container className="star"></Container>
        <Container className="star"></Container>
        <Container className="star"></Container>
        <Container className="star"></Container>
        <Container className="star"></Container>
      </Container>
    </Container>


  )
}

export default Thankyou